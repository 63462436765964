// Helper functions

function toggleClass(element: HTMLElement, classes: string[]) {
  classes.forEach((cls) => element.classList.toggle(cls));
}

function toggleDataAttribute(element: HTMLElement, attr: string) {
  const isOpen = element.getAttribute(attr) == "true";
  element.setAttribute(attr, (!isOpen).toString());
  return !isOpen;
}

function setupClickAndTab(element: HTMLElement, callback: () => void) {
  element.addEventListener("click", callback);
  element.addEventListener("keydown", (e) => {
    if (e.key === "Tab") {
      callback();
    }
  });
}

// Main mobile Menu with tab and click support
const menuTrigger = document.getElementById("menu-trigger") as HTMLElement;
const menuElement = document.getElementById("menu") as HTMLElement;
let currentVisibleItem: HTMLElement | null = null;

if (menuTrigger && menuElement) {
  menuTrigger.addEventListener("click", toggleMenu);

  function toggleMenu() {
    toggleDataAttribute(menuElement, "data-open");
    toggleClass(document.body, ["h-dvh", "overflow-hidden"]);
    toggleDataAttribute(menuTrigger, "aria-expanded");
  }

  // Close the menu when tabbing outside
  document.addEventListener("keydown", (event) => {
    if (event.key === "Tab") {
      if (!menuElement.contains(event.target as Node) && currentVisibleItem != null) {
        if (menuElement.getAttribute("data-open") == "true") {
          menuElement.setAttribute("data-open", "false");
          currentVisibleItem = null;
        } else {
          menuElement.setAttribute("data-open", "true");
        }
      }
    }
  });

  menuTrigger.addEventListener("keydown", function (event) {
    if (event.key === "Tab" && !event.shiftKey) {
      if (menuElement && currentVisibleItem !== menuElement) {
        currentVisibleItem = menuElement;
      }
    }
  });
}

const submenus = document.querySelectorAll<HTMLElement>(".submenu-trigger");

submenus.forEach((submenuTrigger) => {
  submenuTrigger.addEventListener("click", () => toggleDataAttribute(submenuTrigger, "data-open"));
  submenuTrigger.addEventListener("keydown", (e) => {
    if (e.key === "Tab") {
      if (currentVisibleItem && currentVisibleItem !== submenuTrigger) {
        // Close the previously opened
        currentVisibleItem.setAttribute("data-open", "false");
      }
      submenuTrigger.setAttribute("data-open", "true");
      currentVisibleItem = submenuTrigger;
    }
  });
});

const tabTriggers = document.querySelectorAll<HTMLElement>(".tab-triggers");

tabTriggers.forEach((tabTrigger) => {
  tabTrigger.addEventListener("keydown", function (event) {
    if (event.key === "Tab") {
      const item = this.querySelector(".tab-content") as HTMLElement | null;
      if (item) {
        item.setAttribute("data-open", "true");
        currentVisibleItem = item;
        item.addEventListener("focusout", function (event) {
          // Close the tab content if the focus is outside the tab content
          if (!item.contains(event.relatedTarget as Node)) {
            item.setAttribute("data-open", "false");
          }
        });
      }
    }
    // Close using escape key
    if (event.key === "Escape") {
      currentVisibleItem?.setAttribute("data-open", "false");
    }
  });
});

interface SearchElements {
  icon: HTMLElement;
  input: HTMLElement;
  bar: HTMLElement;
}

function setupSearchToggle({ icon, input, bar }: SearchElements) {
  let searchOpen = false;

  setupClickAndTab(icon, () => {
    toggleSearch();
    input.focus();
  });

  document.addEventListener("click", (event) => {
    if (searchOpen && !bar.contains(event.target as Node) && !icon.contains(event.target as Node)) {
      toggleSearch();
    }
  });

  function toggleSearch() {
    searchOpen = toggleDataAttribute(bar, "data-open");
  }
}

// Main Search
const searchElements = {
  icon: document.getElementById("search-icon") as HTMLElement,
  input: document.getElementById("q") as HTMLElement,
  bar: document.getElementById("search-bar") as HTMLElement,
};

if (searchElements.icon && searchElements.input && searchElements.bar) {
  setupSearchToggle(searchElements);
}

// Mobile Search
const mobileSearchElements = {
  icon: document.getElementById("mobile-search-icon") as HTMLElement,
  input: document.getElementById("mobile-q") as HTMLElement,
  bar: document.getElementById("mobile-search-bar") as HTMLElement,
};

if (mobileSearchElements.icon && mobileSearchElements.input && mobileSearchElements.bar) {
  setupSearchToggle(mobileSearchElements);
}
